import { navigate } from 'gatsby'

export const isBrowser = () => typeof window !== 'undefined'

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const getLocalStorage = (variableName) => {
  if (typeof window !== 'undefined') {
    let requiredVar = localStorage.getItem(variableName)
    switch (variableName) {
      case 'auth':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : null
      case 'formAnswers':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : null //{ user_id: null, form_id: null, u_answers : [] }
      case 'formType':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : null //{ formPage: '/', lastStep: 0 }
      case 'propertyFor':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : null //{ state: 'Rent'}
      case 'color-theme':
        return isBrowser && requiredVar
          ? JSON.parse(requiredVar)
          : { mode: 'light' }
      case 'sidebar-expanded':
        return isBrowser && requiredVar ? requiredVar : false
      case 'alertSettings':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : {}
      case 'progress':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : 0
      case 'localQuestions':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : []
      case 'mainFormType':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : {}
      case 'fromPage':
        return isBrowser && requiredVar ? requiredVar : 1
      case 'files':
        return isBrowser && requiredVar ? JSON.parse(requiredVar) : []
      case 'way':
        return isBrowser && requiredVar ? requiredVar : null
      default:
        return {}
    }
  }
}

export const saveQuestionsLocal = (currentQuestion) => {
  if (typeof window !== 'undefined') {
    let localQuestions = localStorage.getItem('localQuestions')
    if (localQuestions) {
      const tempQuestions = addOrUpdateItem(
        JSON.parse(localQuestions),
        currentQuestion
      )
      localStorage.setItem('localQuestions', JSON.stringify(tempQuestions))
      //console.log('tempQuestions', tempQuestions)
    } else {
      localStorage.setItem('localQuestions', JSON.stringify([currentQuestion]))
    }
  }
  // console.log('end')
}

export const getQuestionAnswerLocal = (id) => {
  if (typeof window !== 'undefined') {
    let localQuestions = localStorage.getItem('localQuestions')
    if (localQuestions) {
      const tempQuestions = JSON.parse(localQuestions)
      const currentQuestion = tempQuestions.find(
        (question) => question.id === id
      )
      //console.log('get question', currentQuestion)
      return currentQuestion
    } else {
      return null
    }
  }
}

export const getGroupQuestionsLocal = (group) => {
  if (typeof window !== 'undefined') {
    let currentGroup = []
    let localQuestions = localStorage.getItem('localQuestions')
    if (localQuestions) {
      const tempQuestions = JSON.parse(localQuestions)
      group.map((item) => {
        const currentQuestion = tempQuestions.find(
          (question) => question.id === item.id
        )
        if (currentQuestion) currentGroup.push(currentQuestion)
      })
      // console.log('get currentGroup', currentGroup)
      return currentGroup
    } else {
      return []
    }
  }
}

// add or update element to array per key
export const addOrUpdateItem = (arr, item) => {
  if (arr) {
    let tempArr = []
    const tempItem = arr.find((currentItem) => currentItem.id === item.id)
    if (tempItem) {
      tempArr = arr.map((currentItem) => {
        if (currentItem.id === item.id) currentItem = item
        return currentItem
      })
    } else {
      tempArr = [...arr, item]
    }
    return tempArr
  }
  return []
}

// add or update sub array to array per key
export const addOrUpdateSubArray = (mainArray, subArray) => {
  // console.log(mainArray , subArray)
  subArray.forEach((subObj) => {
    const existingObjectIndex = mainArray.findIndex(
      (mainObj) => mainObj.id === subObj.id
    )

    if (existingObjectIndex !== -1) {
      // If object exists, update it
      mainArray[existingObjectIndex] = {
        ...mainArray[existingObjectIndex],
        ...subObj,
      }
    } else {
      // If object doesn't exist, add it
      mainArray.push({ id: subObj.id, ...subObj })
    }
  })

  return mainArray
}

// add only new images to main array
export const addNewImgsFromSubArray = (mainArray, subArray) => {
  const newMainArray = [...mainArray]
  subArray.forEach((subItem) => {
    // Check if there's already an item with the same name in mainArray
    const existingIndex = newMainArray.findIndex(
      (mainItem) => mainItem.name === subItem.name
    )

    if (existingIndex === -1) {
      // If not found, add the new item to mainArray
      newMainArray.push(subItem)
    } else {
      // If found, you can choose to update the existing item or ignore it
      // For now, let's just log a message
      console.log(
        `Item with name ${subItem.name} already exists in the main array.`
      )
    }
  })
  return newMainArray
}

// get map options config
export const getLocationMapConfig = () => {
  var bangaloreBounds = null
  if (typeof window !== 'undefined') {
    bangaloreBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(24.7934842, 54.8904542),
      new window.google.maps.LatLng(25.3585607, 55.5645216)
    )
  }
  const options = {
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['establishment'],
    componentRestrictions: { country: 'ae' },
    bounds: bangaloreBounds,
    strictBounds: true,
  }
  return options
}

// get random element of array
export const getRandomElementFromArr = (arr) => {
  const randomIndx = Math.floor(Math.random() * arr.length)
  const randomElement = arr[randomIndx]
  return randomElement
}

// smooth scroll
export const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId)
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' })
  }
}

// get agents sub forms
export const splitArray = (arr) => {
  // vars
  let agentIntroArr = []
  let agentRentArr = []
  let agentBuyArr = []
  let agentSellArr = []
  if (arr && arr.length > 0) {
    // Get the first two elements
    agentIntroArr = arr.slice(0, 3)
    // Remove the first two elements
    arr.splice(0, 2)
    arr.map((item) => {
      // get rent questions
      if (parseInt(item.pivot?.order) === 3) {
        agentRentArr.push(item)
      }
      // get rent questions
      if (parseInt(item.pivot?.order) === 4) {
        agentBuyArr.push(item)
      }
      // get rent questions
      if (parseInt(item.pivot?.order) === 5) {
        agentSellArr.push(item)
      }
    })
  }
  return { agentIntroArr, agentRentArr, agentBuyArr, agentSellArr }
}

// get landlord sub forms
export const splitArrayLandlords = (arr) => {
  // vars
  let agentIntroArr = []
  let agentRentArr = []
  let agentSellArr = []
  if (arr && arr.length > 0) {
    // Get the first common elements
    agentIntroArr = arr.slice(0, 11)
    // Remove the first common elements
    arr.splice(0, 11)
    arr.map((item) => {
      // get rent questions
      if (parseInt(item.pivot?.order) === 11) {
        agentRentArr.push(item)
      }

      // get rent questions
      if (parseInt(item.pivot?.order) === 12) {
        agentSellArr.push(item)
      }
    })
  }
  return { agentIntroArr, agentRentArr, agentSellArr }
}

// get landlord sub forms
export const splitArrayLandlordsV2 = (arr) => {
  // vars
  let agentIntroArr = []
  let agentRentArr = []
  let agentSellArr = []
  if (arr && arr.length > 0) {
    // Get the first common elements
    agentIntroArr = arr.slice(0, 7)
    // Remove the first common elements
    arr.splice(0, 7)
    arr.map((item) => {
      // get rent questions
      if (parseInt(item.pivot?.order) === 7) {
        agentRentArr.push(item)
      }

      // get rent questions
      if (parseInt(item.pivot?.order) === 8) {
        agentSellArr.push(item)
      }
    })
  }
  return { agentIntroArr, agentRentArr, agentSellArr }
}

//type of users and colors representing them
export const userTypeClasses = {
  Individual: 'bg-meta-1 text-teal-950 px-2 py-1 rounded-md text-center',
  Landlord: 'bg-lime-200 text-lime-800 px-2 py-1 rounded-md text-center',
  Agent: 'bg-amber-200 text-amber-800 px-2 py-1 rounded-md text-center',
  Corporate: 'bg-orange-300 text-orange-800 px-2 py-1 rounded-md text-center',
}

export const getUserTypeClassName = (type) => {
  return (
    userTypeClasses[type] ||
    'bg-gray-200 text-gray-800 px-2 py-1 rounded-md text-center'
  )
}

// account type
export const getClassNameByUserType = (type) => {
  switch (type) {
    case 'INDIVIDUAL':
      return 'text-center py-2 px-1 text-white rounded-lg shadow-card text-sm bg-meta-3 font-medium text-lexend'
    case 'LANDLORD':
      return 'text-center py-2 px-1 text-white rounded-lg shadow-card text-sm bg-meta-5 font-medium text-lexend'
    case 'AGENT':
      return 'text-center py-2 px-1 text-white rounded-lg shadow-card text-sm bg-meta-1 font-medium text-lexend'
    case 'CORPORATE':
      return 'text-center py-2 px-1 text-white rounded-lg shadow-card text-sm bg-meta-6 font-medium text-lexend'
    default:
      return 'text-center py-2 px-1 text-white rounded-lg shadow-card text-sm bg-meta-4 font-medium text-lexend'
  }
}

// modify id to make order list
export const reorderList = (arr) => {
  const tempArr = arr.map((currentItem, index) => {
    const itemTemp = { ...currentItem, id: index }
    return itemTemp
  })
  return tempArr
}

// remove item from array if exists
export const deleteItem = (arr, itemID) => {
  const tempArr = arr.filter((currentItem) => currentItem.id != itemID)
  return tempArr
}

// export split array to sub arrays depending on parameter in pivot
export const split2subArrays = (arr, splitBY) => {
  // Group arr by element attribute
  const groupedArr = arr.reduce((acc, element) => {
    const tempAttribute = element.pivot[splitBY] || 0
    acc[tempAttribute] = acc[tempAttribute] || []
    acc[tempAttribute].push(element)
    return acc
  }, {})

  // Convert the grouped arr into an array
  const reformattedArr = Object.values(groupedArr)

  return reformattedArr
}

export const handleDownload = (path, filename) => {
  // Create a link element
  const link = document.createElement('a')
  link.href = path
  link.download = filename // Specify the downloaded file name
  link.target = '_blank'
  link.rel = 'White paper'

  // Dispatch a click event on the link
  link.dispatchEvent(new MouseEvent('click'))
}

export const convertImgsDataUrl2Blob = (imgs) => {
  if (imgs && imgs.length > 0) {
    const data = imgs.map((img) => {
      // Check if the dataURL is in the correct format
      if (!img.content.startsWith('data:image/')) {
        throw new Error('Invalid data URL format')
      }

      // Extract the base64-encoded part of the data URL
      const base64Data = img.content.split(';base64,')[1]

      // Check if the base64Data is not empty
      if (!base64Data) {
        throw new Error('Base64 data is empty')
      }

      // Decode the base64-encoded string
      const binaryString = atob(base64Data)

      // Create a Uint8Array from the binary string
      const length = binaryString.length
      const bytes = new Uint8Array(length)
      for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i)
      }

      // Create a Blob from the Uint8Array
      const contentType = img.content.split(';')[0].split(':')[1]
      return new Blob([bytes], { type: contentType })
    })

    return data
  }
  return []
}

export const formatPhoneNumber = (phoneNumber) => {
  // Remove any non-numeric characters (just in case)
  const cleaned = phoneNumber.replace(/\D/g, '')

  // Check if the phone number is valid
  if (cleaned.length === 12) {
    return `+${cleaned.slice(0, 3)} ${cleaned.slice(3, 5)} ${cleaned.slice(
      5,
      8
    )} ${cleaned.slice(8, 12)}`
  }

  // Return the original if it doesn't match the expected length
  return phoneNumber
}

export const addValue2SortArray = (sortArr, col, orderArr, order) => {
  // const idx = array.findIndex((item) => item === val);
  const idx = sortArr.indexOf(col)
  if (idx !== -1) {
    const newOrderArray = [...orderArr]
    newOrderArray[idx] = order
    return [sortArr, newOrderArray]
  }
  const newSortArr = [...sortArr, col]
  const newOrderArr = [...orderArr, order]
  return [newSortArr, newOrderArr]
}

export const removeValueFromSortArray = (sortArr, col, orderArr) => {
  // const idx = array.findIndex((item) => item === val);
  const idx = sortArr.indexOf(col)
  if (idx !== -1) {
    const newSortArray = sortArr.filter((_, i) => i !== idx)
    const newOrderArray = orderArr.filter((_, i) => i !== idx)
    return [newSortArray, newOrderArray]
  }
  return [sortArr, orderArr]
}

export const getOrderValueBySortValue = (sortArr, col, orderArr) => {
  // const idx = array.findIndex((item) => item === val);
  const idx = sortArr.indexOf(col)
  if (idx !== -1) {
    const newOrderArray = [...orderArr]
    return newOrderArray[idx]
  }
  return ''
}

export const handleAgentForm = (proposingTo) => {
  const q_answer = {
    id: 49,
    question: 'What are you proposing?',
    answer: proposingTo === 'property' ? 'Property (Stock)' : 'Customer (Lead)',
  }
  saveQuestionsLocal(q_answer)

  if (proposingTo !== 'property') {
    const q_answer_2 = {
      id: 52,
      question: 'Have you met or talked to your customer?',
      answer: 'No',
    }
    saveQuestionsLocal(q_answer_2)
  }

  localStorage.setItem('way', 'quick-navigate')
  navigate('/agents/offers')
}

export const handleLandlordForm = (propertyFor) => {
  const q_answer = {
    id: 31,
    question: 'Property For',
    answer: propertyFor,
  }
  saveQuestionsLocal(q_answer)

  localStorage.setItem('way', 'quick-navigate')
  navigate('/landlords/offers')
}
